<script lang="ts" setup>
import type { Ref } from 'vue';
import type { CmsElementCategoryText } from '~/types/models/cms/cmsElementTypes';

const props = defineProps<{
    content: CmsElementCategoryText;
}>();

const hits = inject<Ref<number | undefined>>('listingHits', ref(undefined));
const { page } = usePage();
const category = computed(() => page.value?.category);
const { getConfigValue } = useCmsElementConfig(props.content);
const showProductCount = computed(() => getConfigValue('showProductCount'));
const headingType = computed(() => getConfigValue('headingType'));
const headingElement = computed(() => (['h1', 'h2', 'h3'].includes(headingType.value) ? headingType.value : 'h2'));
</script>

<template>
    <div class="flex flex-col justify-center items-center">
        <component
            :is="headingElement"
            v-if="category?.translated?.name"
            class="w-full text-center"
        >
            {{ category.translated.name }}
            <template v-if="showProductCount && hits"> ({{ hits }}) </template>
        </component>
        <div
            v-if="category?.translated?.description"
            class="text-center mt-6 max-w-[800px]"
        >
            {{ category.translated.description }}
        </div>
    </div>
</template>
